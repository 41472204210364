body {
  font-family: "Roboto", sans-serif;
}

.btn-secondary {
  background-color: var(--bs-gray-300);
  color: var(--bs-black)
}

.btn-secondary:nth-child(2n) {
  background-color: var(--bs-gray-200);
  color: var(--bs-black)
}

.btn-success {
  background-color: #25ad3c;
}

.textured-background {
  background-image: url('./assets/images/pattern-40.svg'); /* Path to your SVG */
  background-repeat: repeat; /* Repeat the SVG pattern */
  background-size: cover; /* Adjust as needed, e.g., '50px 50px' for smaller tiles */
}

.background2 {
  background: #7db9e8;
  background-image: url('./assets/images/pattern.svg'); /* Path to your SVG */
  background-repeat: repeat; /* Repeat the SVG pattern */
  height: 100vh; /* change this to whatever else fits your case*/
  background-size: cover;
  overflow: auto;
}

.bg-light-grey {
  background-color: #f6f6f6;
}

.image-background {
  background-image: url('./assets/images/bg3.jpg'); /* Path to your SVG */
  background-repeat: repeat; /* Repeat the SVG pattern */
  height: 100vh; /* change this to whatever else fits your case*/
  background-size: cover;
  overflow: auto;
}


.background {
  background: #c0e7f8;

  /* background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#7db9e8), color-stop(85%,#7db9e8), color-stop(100%,#089a56));
  background: -webkit-linear-gradient(top,  #7db9e8 0%, #7db9e8 85%, #089a56 100%);
  background:    -moz-linear-gradient(top,  #7db9e8 0%, #7db9e8 85%, #089a56 100%);
  background:     -ms-linear-gradient(top,  #7db9e8 0%, #7db9e8 85%, #089a56 100%);
  background:      -o-linear-gradient(top,  #7db9e8 0%, #7db9e8 85%, #089a56 100%);
  background:         linear-gradient(to bottom, #7db9e8 0%, #7db9e8 85%, #089a56 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7db9e8', endColorstr='#089a56',GradientType=0 );
  background-attachment: fixed; */
  height: 100vh; /* change this to whatever else fits your case*/
  background-size: cover;
  overflow: auto;
}

.venue-header {
  background: #bbc7d0;
}

.button {
  margin: 2px 0;
}

.m1 {
  margin: 1em 0;
}

.m2 {
  margin: 2px;
}

.m-top {
  margin-top: 15px;
}

.m15 {
  margin: 15px 0;
}

.p15 {
  padding: 10px;
}

.text-centre {
  text-align: center;
}

.leaderboard{
  word-wrap: break-word;
}

.simple-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.simple-table table {
  width: 100%;
  table-layout: fixed;  /* add table layout fixed */
  overflow: hidden; /* add overflow hidden */
  border-collapse: collapse;
}
.simple-table > table td,
.simple-table > table th {
  word-wrap: break-word !important;
}

.bottom-border {
  border-bottom: 2px dotted gray;
}

.left-border {
  border-left: 2px dotted gray;
}

.right-border {
  border-right: 2px dotted gray;
}

/* .score-column {
  border: 2px double rgb(69, 142, 35);
  border-radius: 30px;
  background-color: #ffffaaaa;
} */

.container {
  padding-right: calc(1.0rem * .5);
  padding-left: calc(1.0rem * .5);
}

.button-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1320px;
  padding: 0;
  margin: 0 auto;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 0.5vw;
  width: 100%;
  height: 100%;
}

.grid-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 100%;
}

.button-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distributes space between top and bottom text */
  height: 100%; /* Ensure it takes full height of the button */
  width: 100%; /* Ensure it takes full width of the button */
  padding: 0 1px; /* Optional: Add horizontal padding if needed */
  text-align: center;
  overflow: hidden;
  font-size: calc(1vw + 0.5vh); /* Responsive font size */
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
  align-items: center;
}

.button-top-text {
  margin-bottom: auto; /* Pushes the top text to the top */
}

/* Loading animation */

.loading-container {
  position: fixed;
  top: 58px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* background-image: url('./assets/images/pattern-30.svg'); */
  background-image: url('./assets/images/bg3.jpg');
  background-repeat: repeat;
  background-size: cover;
  z-index: 9999;
}

.pulsing-image {
  animation: pulse 1.5s infinite;
  max-width: 80%;
  max-height: 80%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}